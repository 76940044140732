@tailwind base;
@tailwind components;
@tailwind utilities;

.font-0 {
  font-family: "Wallpoet", cursive;
}

.font-1 {
  font-family: "Audiowide", cursive;
}

.font-2 {
  font-family: "Train One", cursive;
}

.font-3 {
  font-family: "Faster One", cursive;
}

.font-4 {
  font-family: "Libre Barcode 39 Text", cursive;
}

.font-5 {
  font-family: "Londrina Solid", cursive;
}

.font-6 {
  font-family: "Monoton", cursive;
}

.font-7 {
  font-family: "Sansita Swashed", cursive;
}

.font-8 {
  font-family: "Pacifico", cursive;
}

.font-9 {
  font-family: "Palette Mosaic", cursive;
}

.font-10 {
  font-family: "Luckiest+Guy", cursive;
}

.font-11 {
  font-family: "Cabin Sketch", cursive;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title {
  color: red;
}

html,
body,
#root {
  height: 100%;
}

.concord-img-gradient {
  background: rgba(0, 0, 0, 0.4);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.8)),
    color-stop(60%, rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0.8))
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.8) 0,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background-image: -moz-
    oldlinear-gradient(
      to top,
      rgba(0, 0, 0, 0.8) 0,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.8) 100%
    );
  background-image: -o-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.8) 0,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

@media only screen and (min-width: 550px) and (max-width: 949px) and (orientation: portrait),
  only screen and (min-width: 400px) and (max-width: 549px),
  only screen and (min-width: 350px) and (max-width: 399px),
  only screen and (max-width: 349px) {
  .concord-img-gradient {
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(0, 0, 0, 0.8)),
      color-stop(40%, rgba(0, 0, 0, 0)),
      color-stop(75%, rgba(0, 0, 0, 0)),
      to(rgba(0, 0, 0, 0.8))
    );
    background-image: -webkit-linear-gradient(
      bottom,
      rgba(0, 0, 0, 0.8) 0,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0) 75%,
      rgba(0, 0, 0, 0.8) 100%
    );
    background-image: -moz-
      oldlinear-gradient(
        to top,
        rgba(0, 0, 0, 0.8) 0,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 0) 75%,
        rgba(0, 0, 0, 0.8) 100%
      );
    background-image: -o-linear-gradient(
      bottom,
      rgba(0, 0, 0, 0.8) 0,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0) 75%,
      rgba(0, 0, 0, 0.8) 100%
    );
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8) 0,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0) 75%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
}

.concord-img-gradient-light {
  background: rgba(255, 255, 255, 0.4);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(255, 255, 255, 0.8)),
    color-stop(60%, rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.8))
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgba(255, 255, 255, 0.8) 0,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 255, 255, 0.8) 100%
  );
  background-image: -moz-
    oldlinear-gradient(
      to top,
      rgba(255, 255, 255, 0.8) 0,
      rgba(255, 255, 255, 0) 60%,
      rgba(255, 255, 255, 0.8) 100%
    );
  background-image: -o-linear-gradient(
    bottom,
    rgba(255, 255, 255, 0.8) 0,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 255, 255, 0.8) 100%
  );
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.8) 0,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 255, 255, 0.8) 100%
  );
}

@media only screen and (min-width: 550px) and (max-width: 949px) and (orientation: portrait),
  only screen and (min-width: 400px) and (max-width: 549px),
  only screen and (min-width: 350px) and (max-width: 399px),
  only screen and (max-width: 349px) {
  .concord-img-gradient-light {
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(255, 255, 255, 0.8)),
      color-stop(40%, rgba(255, 255, 255, 0)),
      color-stop(75%, rgba(255, 255, 255, 0)),
      to(rgba(255, 255, 255, 0.8))
    );
    background-image: -webkit-linear-gradient(
      bottom,
      rgba(255, 255, 255, 0.8) 0,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0) 75%,
      rgba(255, 255, 255, 0.8) 100%
    );
    background-image: -moz-
      oldlinear-gradient(
        to top,
        rgba(255, 255, 255, 0.8) 0,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0) 75%,
        rgba(255, 255, 255, 0.8) 100%
      );
    background-image: -o-linear-gradient(
      bottom,
      rgba(255, 255, 255, 0.8) 0,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0) 75%,
      rgba(255, 255, 255, 0.8) 100%
    );
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.8) 0,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0) 75%,
      rgba(255, 255, 255, 0.8) 100%
    );
  }
}
